import React from 'react'

const Text = props => {
  const { name, id, value, onChange } = props
  return (
    <input
      type="text"
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      className="max-w-sm focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-200 rounded-lg px-4 py-2"
    />
  )
}

export default Text
