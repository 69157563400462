import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import MultiText from '../components/Form/MultiText'
import Text from '../components/Form/Text'
import Select from '../components/Select'
import DescriptionListItem from '../components/DescriptionListItem'
import Loading from '../components/Loading';
import ResourceNotFound from '../components/ResourceNotFound';
import PageHeader from '../components/PageHeader';
import ButtonLoadingSpinner from '../components/ButtonLoadingSpinner';
import SuccessMessage from '../components/SuccessMessage';
import ErrorMessage from '../components/ErrorMessage';
import * as icons from '../components/Icons';

const AccountEdit = () => {
  const [accountDetail, setAccountDetail] = useState(null)
  const [accountNotFound, setAccountNotFound] = useState(null)
  const [apiCallInProgress, setApiCallInProgress] = useState(false)

  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessages, setErrorMessages] = useState(null)

  // Get the "number" parameter from the URL, this represents the account number
  const { number } = useParams()

  const updateAccountDetail = (field, value) => {
    const copyOfDetail = JSON.parse(JSON.stringify(accountDetail))
    copyOfDetail[field] = value
    setAccountDetail(copyOfDetail)
  }

  const updateAccountName = event => {
    const { value } = event.currentTarget
    updateAccountDetail('Name', value)
  }

  const updateAccountScope = item => {
    updateAccountDetail('Scope', item.value)
  }

  const updateAccountEnvironment = item => {
    updateAccountDetail('Environment', item.value)
  }

  const updateAccountBillingContacts = contacts => {
    updateAccountDetail('BillingContacts', contacts)
  }

  const updateAccountServiceContacts = contacts => {
    updateAccountDetail('ServiceContacts', contacts)
  }

  const updateAccountSecurityContacts = contacts => {
    updateAccountDetail('SecurityContacts', contacts)
  }

  const updateLastChangedBy = contacts => {
    updateAccountDetail('LastChangedBy', contacts)
  }

  const updateAccountStatus = item => {
    updateAccountDetail('Status', item.value)
  }

  const submitForm = async () => {
    setApiCallInProgress(true)

    const body = {
      name: accountDetail.Name,
      scope: accountDetail.Scope,
      environment: accountDetail.Environment,
      billingContacts: accountDetail.BillingContacts,
      serviceContacts: accountDetail.ServiceContacts,
      securityContacts: accountDetail.SecurityContacts,
      status: accountDetail.Status
    };

    // make API call with parameters and use promises to get response
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/accounts/${number}`,
      data: JSON.stringify(body),
      headers: { 'content-type': 'application/json' },
    }).then(response => {
      setErrorMessages(null)
      setApiCallInProgress(false)
      setSuccessMessage(response.data.Message)
    }).catch(error => {
      console.log(error);
      const tempErrorMessages = []
      const fieldNameMap = {
        name: "Name",
        scope: "Scope",
        environment: "Environment",
        billingContacts: "Billing contacts",
        serviceContacts: "Service contacts",
        securityContacts: "Security contacts",
        status: "Status"
      };
      for (const [field, messages] of Object.entries(error.response.data.errors)) {
        const mappedField = fieldNameMap[field]
        for (const msg of messages) {
          tempErrorMessages.push(`${mappedField}: ${msg}`)
        }
      }
      setSuccessMessage(null)
      setApiCallInProgress(false)
      setErrorMessages(tempErrorMessages)
    });
  }

  const getAccountDetails = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/accounts/${number}`,
        headers: { 'content-type': 'application/json' },
      })
      setAccountDetail(response.data)
    } catch (error) {
      console.error('error', error)
      if (error.response && error.response.status === 404) {
        setAccountNotFound(error.response.data)
      }
    }
  }

  // When the component renders for the first time, load the data from API
  useEffect(() => {
    const makeInitialApiCalls = async () => {
      await getAccountDetails()
    }

    makeInitialApiCalls()
  }, [number])

  if (accountNotFound) {
    return <ResourceNotFound pageTitle="Account view" message={accountNotFound.Message} />
  }

  // React will try and render the component before the useEffect executes, so we need to handle
  // what to show when we don't yet have data to show
  if (accountDetail === null) {
    return <Loading pageTitle="Account edit" />
  }

  const accountScopes = [
    { label: 'Managed', value: 'managed' },
    { label: 'Core', value: 'core' },
    { label: 'Bespoke', value: 'bespoke' }
  ]
  const selectedScope = accountScopes.find(scope => scope.value === accountDetail.Scope)

  const accountEnvironments = [
    { label: 'Development', value: 'development' },
    { label: 'Test', value: 'test' },
    { label: 'Production', value: 'production' }
  ]
  const selectedEnvironment = accountEnvironments.find(environment => environment.value === accountDetail.Environment)

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Terminated', value: 'Terminated' }  
  ]

  const selectedStatus = statusOptions.find(status => status.value === accountDetail.Status)


  const accountNameInput = <Text name="account_name" id="account_name" value={accountDetail.Name} onChange={updateAccountName} />
  const accountScopeInput = <Select placeholderText={"Choose a scope"} items={accountScopes} selected={selectedScope} onSelect={updateAccountScope} />
  const accountEnvironmentInput = <Select placeholderText={"Choose a environment"} items={accountEnvironments} selected={selectedEnvironment} onSelect={updateAccountEnvironment} />
  const billingContactsInput = <MultiText value={accountDetail.BillingContacts} onChange={updateAccountBillingContacts} />
  const securityContactsInput = <MultiText value={accountDetail.SecurityContacts} onChange={updateAccountSecurityContacts} />
  const serviceContactsInput = <MultiText value={accountDetail.ServiceContacts} onChange={updateAccountServiceContacts} />
  const accountStatusInput = <Select placeholderText={"Choose a status"} items={statusOptions} selected={selectedStatus} onSelect={updateAccountStatus} />

  const projects = accountDetail.Projects.map(proj => {
    return {
      label: proj.Name,
      url: `/projects/${proj.Tag}`
    }
  })

  const services = accountDetail.Services.map(svc => {
    return {
      label: svc.Name,
      url: `/services/${svc.Tag}`
    }
  })

  return (
    <>
      {/* Page Header */}
      <PageHeader title={accountDetail.Name} />

      {/* Container */}
      <div className="container mt-10 flex">
        <div className="w-1/3">
          <div className="pr-5">
            <div className="text-lg font-bold tracking-normal text-gray-900">Edit account information</div>
            <div className="text-gray-600 mt-1">Here you can update the information stored about the account.</div>
          </div>
        </div>
        <div className="w-2/3 bg-white rounded-lg box-border shadow-md">
          <SuccessMessage message={successMessage} />
          <ErrorMessage messages={errorMessages} />

          <DescriptionListItem label="Account name:" value={accountNameInput} />
          <DescriptionListItem label="Account number:" value={String(accountDetail.Number)} />
          <DescriptionListItem label="Scope:" value={accountScopeInput} />
          <DescriptionListItem label="Status:" value={accountStatusInput} />
          <DescriptionListItem label="Billing contacts:" value={billingContactsInput} />
          <DescriptionListItem label="Security contacts:" value={securityContactsInput} />
          <DescriptionListItem label="Service contacts:" value={serviceContactsInput} />
          <DescriptionListItem label="Environment:" value={accountEnvironmentInput} />
          <DescriptionListItem
            label="Projects:"
            value={{ 
              type: "table", 
              icon: icons.project,
              description: "The projects linked to an account cannot be edited from here. Edit the project directly to manage its account relationships.",
              items: projects 
            }}
          />
          <DescriptionListItem
            label="Services:" 
            value={{ 
              type: "table",
              icon: icons.service,
              description: "The services linked to an account cannot be edited from here. Edit the service directly to manage its account relationships.",
              items: services 
            }} 
          />
          <div className="bg-gray-200 rounded-bl rounded-br flex justify-end py-3 px-8">
            <button className="bg-primary text-white rounded py-2 px-5 flex items-center" type="button" onClick={submitForm}>
              <div>Update account</div>
              {apiCallInProgress && <ButtonLoadingSpinner />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountEdit;