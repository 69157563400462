import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Fuse from 'fuse.js';
import axios from 'axios';
import PageHeader from '../components/PageHeader';
import StackedListItem from '../components/StackedListItem';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';

const AccountList = props => {
  const [accounts, setAccounts] = useState(null)
  const [visibleAccounts, setVisibleAccounts] = useState(null)
  const [accountSearch, setAccountSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 6

  const updateAccountSearchFromForm = (event) => {
    setAccountSearch(event.currentTarget.value)
  }

  useEffect(() => {
    getAccounts()
  }, [])

  useEffect(() => {
    if (accounts === null) {
      return;
    }

    if (accountSearch.length < 3) {
      setVisibleAccounts(accounts)
      return;
    }

    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      minMatchCharLength: 3,
      // location: 0,
      threshold: 0.3,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys: [
        "Name",
        "Number"
      ]
    };

    const fuse = new Fuse(accounts, options);
    const results = fuse.search(accountSearch)
    const mappedResults = results.map(item => item.item)
    setVisibleAccounts(mappedResults)
    setCurrentPage(1)
  }, [accountSearch, accounts])

  const getAccounts = () => {
    axios({
      method: 'GET',
      url:`${process.env.REACT_APP_API_BASE_URL}/accounts`,
      headers: { 'content-type': 'application/json' },
    }).then(response => {
        setAccounts(response.data.Accounts)
        setVisibleAccounts(response.data.Accounts)
    })
  }

  if (visibleAccounts === null) {
    return <Loading pageTitle="Accounts" />
  }

    // Get projects based on pagination
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = visibleAccounts.slice(indexOfFirstPost, indexOfLastPost)

  const accountListItems = currentPosts.map((account, key) => {
    const label = (<div>
      <div>{account.Name}</div>
      <div className="text-gray-600 text-sm">{account.Number}</div>
    </div>)
    return <StackedListItem key={key} label={label} linkTo={`accounts/${account.Number}`} />
  })

  const accountList = (
    <ul className="w-full max-w-md bg-white rounded-lg box-border shadow-md ">
      { visibleAccounts.length > 0 ? accountListItems : <StackedListItem label={'No accounts'}/> }
    </ul>
  )

  const totalPages = Math.max(1, Math.ceil(visibleAccounts.length/postsPerPage))

  const increasePage = () => {
    if (currentPage+1 <= totalPages) {
      setCurrentPage(currentPage+1);
    }
  }

  const decreasePage = () => {
    if (currentPage-1 > 0) {
      setCurrentPage(currentPage-1);
    }
      
  }

  const addButton = (<NavLink className="hover:bg-indigo-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium bg-gray-100" to="/accounts/create">
    Add account
  </NavLink>)

  return (
    <>
        {/* Page Header */}
        <PageHeader title="Accounts" buttons={[addButton]} />

        {/* Search bar */}
        <div className="container mt-5">
          <label className="block" htmlFor="accountname">Search bar</label>
          <input type="text" className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" onChange={updateAccountSearchFromForm} id="accountsearch" placeholder="Enter account number" value={accountSearch} />
        </div>

        {/* Container */}
        <div className="container mt-10">
          <div className="flex flex-col items-center max-w-md">
            {accountList}          
            <Pagination decreasePage={decreasePage} increasePage={increasePage} currentPage={currentPage} totalPages={totalPages} />
          </div>
        </div> 
    </>
  );
}

export default AccountList; 