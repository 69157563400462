import React from 'react'
import PageHeader from './PageHeader'

const ResourceNotFound = props => {
  return (
    <div className="w-full">
      {props.pageTitle && <PageHeader title={props.pageTitle} />}
      <div className="container">
        <div className="flex items-center justify-start mt-10 bg-red-400 rounded-lg py-3 px-4">
          <svg className="mr-5 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div className="font-medium text-white text-regular ">
            {props.message ? props.message : 'Resource not found'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourceNotFound
