import React from 'react'

const Pagination = props => {
  const { decreasePage, currentPage, totalPages, increasePage } = props

  return (
    <div className="flex mt-5">
      <button onClick={decreasePage} className="w-24 border-gray-200 border-r hover:bg-purple-600 bg-primary py-2 rounded-l-lg text-sm text-white font-medium text-center">Previous</button>
      <div className="bg-primary px-3 py-2 text-sm text-white font-medium text-center">{currentPage} out of {totalPages}</div>
      <button onClick={increasePage} className="w-24 border-gray-200 border-l hover:bg-purple-600 bg-primary py-2 rounded-r-lg text-sm text-white font-medium text-center">Next</button>
    </div>
  )
}

export default Pagination
