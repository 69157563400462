import React, { useState, useEffect, useRef } from 'react'
import SelectOption from './SelectOption'

const MultiSelect = props => {
  const [open, setOpen] = useState(false)
  const { placeholderText, items, selected, onOptionClick: propsOnOptionClick } = props

  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const toggleOpen = () => {
    setOpen(!open)
  }

  const onOptionClick = (item, wasSelected) => {
    propsOnOptionClick(item, wasSelected)
  }

  const dropdownItems = items.map((item, key) => {
    const isSelected = selected.some(selectedItem => {
      return selectedItem.value === item.value
    })
    return <SelectOption key={key} label={item.label} value={item.value} onClick={onOptionClick} isSelected={isSelected} />
  })

  const buttonText = selected.length > 0 ? `${selected.length} selected` : placeholderText

  return (
    <div className="mt-1 relative w-79" ref={ref}>
      <button onClick={toggleOpen} type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm">
        <span className={"block truncate " + (selected.length ? 'text-black' : 'text-gray-500')}>{buttonText}</span>
        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          {/* <!-- Heroicon name: solid/selector --> */}
          <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      </button>

      {open && <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50">
        <ul tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" className="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {dropdownItems}
        </ul>
      </div>}
    </div>
  )
}

export default MultiSelect