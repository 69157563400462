import { useState, useEffect } from 'react';
import { useParams, NavLink } from "react-router-dom";
import axios from 'axios'
import DescriptionListItem from '../components/DescriptionListItem'
import Loading from '../components/Loading';
import PageHeader from '../components/PageHeader';
import ResourceNotFound from '../components/ResourceNotFound';
import Swal from 'sweetalert2'
import Deleting from '../components/Deleting';
import { account } from '../components/Icons';

const HomeRead = () => {
  const [projectNotFound, setProjectNotFound] = useState(null)
  const [projects, setProjects] = useState(null)
  const [services, setServices] = useState(null)
  const [accounts, setAccounts] = useState(null)

 
  useEffect(() => {
    getProjects()
    getServices()
    getAccounts()
  }, [])

  const getProjects = () => {
    axios({
      method: 'GET',
      url:`${process.env.REACT_APP_API_BASE_URL}/projects`,
      headers: { 'content-type': 'application/json' },
    }).then(response => {
        setProjects(response.data.Projects)
    })
  }

  const getServices = () => {
    axios({
      method: 'GET',
      url:`${process.env.REACT_APP_API_BASE_URL}/services`,
      headers: { 'content-type': 'application/json' },
    }).then(response => {
        setServices(response.data.Services)
    })
  }

  const getAccounts = () => {
    axios({
      method: 'GET',
      url:`${process.env.REACT_APP_API_BASE_URL}/accounts`,
      headers: { 'content-type': 'application/json' },
    }).then(response => {
        setAccounts(response.data.Accounts)
    })
  }

  if (projects === null) {
    return <Loading pageTitle="Project view" />
  }

  if (services === null) {
    return <Loading pageTitle="Project view" />
  }

  if (accounts === null) {
    return <Loading pageTitle="Accounts" />
  }

  if (projectNotFound) {
    return <ResourceNotFound pageTitle="Project view" message={projectNotFound.Message} />
  }

  var listOfAccounts = [];

  //console.log(projects)

  for (var i = 0, l = projects.length; i < l; i++){
    for (let j = 0; j < projects[i]['Accounts'].length; j++) {
        listOfAccounts.push(projects[i]['Accounts'][j]);
      }
  }
   //console.log("list of accounts is: ")
   let uniqueAssignedAccounts = [...new Set(listOfAccounts)];
   //console.log(uniqueAssignedAccounts)


  var unassignedAccounts = accounts.length - uniqueAssignedAccounts.length

  return (
    <>
      {/* Page Header */}


      {/* Container */}
      <div className="container mt-5 grid grid-cols-3 gap-2 place-items-stretch h-48">
        
        {/* PROJECTS */}
        <div className="mx-2 gap-4 my-4">
            <div className="w-full">
                <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <p className="text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200 md:container md:mx-auto text-center">
                        Total number of projects
                    </p>
                    <div className="items-end space-x-2 my-6 ">
                        <p className="text-5xl text-black dark:text-white font-bold text-center">
                            {projects.length}
                        </p>
                    </div>
                </div>
            </div>
          </div>

          {/* SERVICES */}
        <div className="mx-2 gap-4 my-4">
            <div className="w-full">
                <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <p className="text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200 md:container md:mx-auto text-center">
                        Total number of services
                    </p>
                    <div className="items-end space-x-2 my-6 ">
                        <p className="text-5xl text-black dark:text-white font-bold text-center">
                            {services.length}
                        </p>
                        {/* <span className="text-green-500 text-xl font-bold flex items-center">
                            <svg width="20" fill="currentColor" height="20" className="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                </path>
                            </svg>
                            22%
                        </span> */}
                    </div>
                </div>
            </div>
          </div>

        {/* ACCOUNTS */}
        <div className="mx-2 gap-4 my-4">
            <div className="w-full">
                <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <p className="text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200 md:container md:mx-auto text-center">
                        Total number of accounts
                    </p>
                    <div className="items-end space-x-2 my-6 ">
                        <p className="text-5xl text-black dark:text-white font-bold text-center">
                            {accounts.length}
                        </p>
                        {/* <span className="text-green-500 text-xl font-bold flex items-center">
                            <svg width="20" fill="currentColor" height="20" className="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                </path>
                            </svg>
                            22%
                        </span> */}
                    </div>

                </div>
            </div>
          </div>
      </div>
      {/* ASSIGNED ACCOUNTS */}
      <div className="container grid grid-cols-2 gap-2 place-items-stretch h-48">
          <div className="mx-2 gap-4 my-4">
            <div className="w-full">
                <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <p className="text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200 md:container md:mx-auto text-center">
                        Total number of assigned accounts
                    </p>
                    <div className="items-end space-x-2 my-6 ">
                        <p className="text-5xl text-green-500 dark:text-white font-bold text-center">
                            {uniqueAssignedAccounts.length}
                        </p>
                        {/* <span className="text-green-500 text-xl font-bold flex items-center">
                            <svg width="20" fill="currentColor" height="20" className="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                </path>
                            </svg>
                            22%
                        </span> */}
                    </div>

                </div>
            </div>
          </div>
          {/* UNASSIGNED ACCOUNTS */}
          <div className="mx-2 gap-4 my-4">
                <div className="w-full">
                    <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                        <p className="text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200 md:container md:mx-auto text-center">
                            Total number of unassigned accounts
                        </p>
                        <div className="items-end space-x-2 my-6 ">
                            <p className="text-5xl text-red-500 dark:text-white font-bold text-center">
                                {unassignedAccounts}
                            </p>
                            {/* <span className="text-green-500 text-xl font-bold flex items-center">
                                <svg width="20" fill="currentColor" height="20" className="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                    </path>
                                </svg>
                                22%
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default HomeRead;