import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserAuthContext } from "../App"

const PrivateRoute = props => {
  const authContext = useContext(UserAuthContext)
  const authenticated = authContext.session && authContext.user
  return authenticated ? (<Route {...props} />) :
    (<Redirect to="/login" />);
};

export default PrivateRoute;