import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios'
import DescriptionListItem from '../components/DescriptionListItem'
import Loading from '../components/Loading';
import PageHeader from '../components/PageHeader';
import MultiText from '../components/Form/MultiText';
import Select from '../components/Select'
import MultiSelect from '../components/MultiSelect';
import ButtonLoadingSpinner from '../components/ButtonLoadingSpinner';
import ResourceNotFound from '../components/ResourceNotFound';

const ServiceEdit = () => {
  const [serviceNotFound, setServiceNotFound] = useState(null)
  const [serviceDetail, setServiceDetail] = useState(null)
  const [projectsForSelection, setProjectsForSelection] = useState([])
  const [apiCallInProgress, setApiCallInProgress] = useState(false)
  const [accountsForSelection, setAccountsForSelection] = useState([])
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessages, setErrorMessages] = useState([])

  const baseUpdateDetail = (field, value) => {
    const copyOfDetail = JSON.parse(JSON.stringify(serviceDetail))
    copyOfDetail[field] = value
    setServiceDetail(copyOfDetail)
  }

  const updateProjectNameFromForm = item => {
    const project = {
      Name: item.label,
      Tag: item.value
    }

    const newServiceDetail = { ...serviceDetail, Project: project, Accounts: [] }
    setServiceDetail(newServiceDetail)
    getAccountsForService(newServiceDetail)
  }

  const updateServiceBillingContacts = contacts => {
    baseUpdateDetail('BillingContacts', contacts)
  }

  const updateServiceServiceContacts = contacts => {
    baseUpdateDetail('ServiceContacts', contacts)
  }

  const updateServiceSecurityContacts = contacts => {
    baseUpdateDetail('SecurityContacts', contacts)
  }

  // Get the "tag" parameter from the URL
  const { tag: serviceTag } = useParams()

  const updateAccountNumbers = (item, wasSelected) => {
    let copyOfServiceDetail = JSON.parse(JSON.stringify(serviceDetail))
    if (wasSelected) {
      // If it's been clicked and it WAS selected, it should now be unselected
      copyOfServiceDetail.Accounts = copyOfServiceDetail.Accounts.filter(acct => acct !== item.value)
    } else {
      copyOfServiceDetail.Accounts.push(item.value)
    }

    setServiceDetail(copyOfServiceDetail)
  }

  /**
   * Make an API request and get all the projects for the dropdown
   */
  const getProjects = async () => {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/projects`,
      headers: { 'content-type': 'application/json' },
    })

    const formattedProjects = response.data.Projects.map(proj => {
      return { label: proj.Tag, value: proj.Tag }
    })

    setProjectsForSelection(formattedProjects)
  }

  /**
   * Make an API request and get all the choosable accounts for the service
   */
  const getAccountsForService = async (service) => {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/projects/${service.Project.Tag}`,
      headers: { 'content-type': 'application/json' },
    })

    const formattedAccounts = response.data.Accounts.map(acct => {
      return { label: acct, value: acct }
    })

    setAccountsForSelection(formattedAccounts)
  }

  /**
   * Make an API request to get the service
   */
  const getService = async () => {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/services/${serviceTag}`,
      headers: { 'content-type': 'application/json' },
    })

    setServiceDetail(response.data)
    return response.data
  }


  /**
   * Define the function calls required to initialise the data
   */
  const initialiseData = async () => {
    await getProjects()
    getService()
      .then(getAccountsForService)
      .catch(error => {
        // Handle the 404 error if we don't find the service
        console.error('error', error)
        if (error.response && error.response.status === 404) {
          setServiceNotFound(error.response.data)
          throw error
        }
      });
  }

  /**
   * On component mount, call the initialiseData function
   */
  useEffect(() => initialiseData(), [])


  if (serviceNotFound) {
    return <ResourceNotFound pageTitle="Service view" message={serviceNotFound.Message} />
  }

  // React will try and render the component before the useEffect executes, so we need to handle
  // what to show when we don't yet have data to show
  if (serviceDetail === null) {
    return <Loading pageTitle="Service view" />
  }

  const billingContactsInput = <MultiText value={serviceDetail.BillingContacts} onChange={updateServiceBillingContacts} />
  const serviceContactsInput = <MultiText value={serviceDetail.ServiceContacts} onChange={updateServiceServiceContacts} />
  const securityContactsInput = <MultiText value={serviceDetail.SecurityContacts} onChange={updateServiceSecurityContacts} />


  const callAPI = () => {
    setApiCallInProgress(true)

    const body = {
      "Project": serviceDetail.Project.Tag,
      "BillingContacts": serviceDetail.BillingContacts,
      "SecurityContacts": serviceDetail.SecurityContacts,
      "ServiceContacts": serviceDetail.ServiceContacts,
      "Accounts": serviceDetail.Accounts.map(a => ({ number: a }))
    };

    // make API call with parameters and use promises to get response
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/services/${serviceTag}`,
      data: JSON.stringify(body),
      headers: { 'content-type': 'application/json' },
    }).then(response => {
      setErrorMessages([])
      setApiCallInProgress(false)
      setSuccessMessage(response.data.Message)
    }).catch(error => {
      console.log(error);
      const tempErrorMessages = []
      const fieldNameMap = {
        Project: "Project name",
        BillingContacts: "Billing contact email",
        SecurityContacts: "Security contact email",
        ServiceContacts: "Service contact email",
        Accounts: "Account numbers"
      }
      for (const [field, messages] of Object.entries(error.response.data.errors)) {
        const mappedField = fieldNameMap[field]
        for (const msg of messages) {
          tempErrorMessages.push(`${mappedField}: ${msg}`)
        }
      }
      setSuccessMessage("")
      setApiCallInProgress(false)
      setErrorMessages(tempErrorMessages)
    });
  }

  const selectedProjectForInput = {
    label: serviceDetail.Project.Tag,
    value: serviceDetail.Project.Tag
  }

  const projectInput = <Select
    placeholderText={'Choose a project'}
    selected={selectedProjectForInput}
    onSelect={updateProjectNameFromForm}
    items={projectsForSelection}
  />

  const accountsInput = (
    <div className="-mt-1">
      <MultiSelect
        placeholderText={'Choose service accounts'}
        items={accountsForSelection}
        selected={serviceDetail.Accounts.map(a => { return { label: a, value: a } })}
        onOptionClick={updateAccountNumbers}
      />
    </div>
  )

  const renderedErrorMessages = errorMessages.map((msg, key) => <div key={key}>{msg}</div>)

  return (
    <>
      {/* Page Header */}
      <PageHeader title={serviceDetail.Service} />

      {/* Container */}
      <div className="container mt-10 flex">
        <div className="w-1/3">
          <div className="pr-5">
            <div className="text-lg font-bold tracking-normal text-gray-900">Service information</div>
            <div className="text-gray-600 mt-1">This is all the information about the service. You can click through to edit any connected accounts and the parent project.</div>
          </div>
        </div>
        {/* Form in white box */}
        <div className="w-2/3 bg-white rounded-lg box-border shadow-md">

          {/* Success and error messages post-validation */}
          {successMessage && (
            <div className="p-8">
              <div className="rounded bg-green-500 text-white text-base p-4 mb-5">
                {successMessage}
              </div>
            </div>
          )}
          {errorMessages.length > 0 && (
            <div className="p-8">
              <div className="rounded bg-red-500 text-white text-base p-4 mb-5">
                {renderedErrorMessages}
              </div>
            </div>
          )}

          <DescriptionListItem label="Service name:" value={serviceDetail.Service} />
          <DescriptionListItem label="Project Tag:" value={projectInput} />
          <DescriptionListItem label="Tag:" value={serviceDetail.Tag} />
          <DescriptionListItem label="Billing contacts:" value={billingContactsInput} />
          <DescriptionListItem label="Security contacts:" value={securityContactsInput} />
          <DescriptionListItem label="Service contacts:" value={serviceContactsInput} />
          <DescriptionListItem label="Accounts:" value={accountsInput} />
          <div className="bg-gray-200 rounded-bl rounded-br flex justify-end py-3 px-8">
            <button className="bg-primary text-white rounded py-2 px-5 flex items-center" type="button" onClick={callAPI}>
              <div>Update service</div>
              {apiCallInProgress && <ButtonLoadingSpinner />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceEdit;