const config = {
  Auth: {
      // Amazon Cognito Region
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION,

      // Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

      // Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,

      // Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // Hosted UI configuration
      oauth: {
          domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
          scope: ['email', 'profile', 'openid'],
          redirectSignIn: process.env.REACT_APP_COGNITO_USER_POOL_REDIRECT_SIGN_IN_URL,
          redirectSignOut: process.env.REACT_APP_COGNITO_USER_POOL_REDIRECT_SIGN_OUT_URL,
          responseType: 'code'
      }
  }
}

export default config