import React from 'react'

const ErrorMessage = props => {
  const { messages } = props

  if (messages === null) {
    return null
  }

  const renderedMessages = messages.map((msg, key) => <div className="mb-2" key={key}>{msg}</div>)

  return (
    <div className="p-8">
      <div className="rounded bg-red-500 text-white text-base p-4 mb-5">
        {renderedMessages}
      </div>
    </div>
  )
}

export default ErrorMessage
