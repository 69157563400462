import React from 'react'

const PageHeader = props => {
  return (
    <div className="w-full bg-gray-300">
        <div className="container flex items-center justify-between h-12">
            <div className="text-2xl">{ props.title }</div>
            { props.buttons && <div>{props.buttons.map((el, key) => <div key={key} className="ml-2 inline-block">{el}</div>)}</div>}
        </div>
    </div>
  )
}

export default PageHeader
