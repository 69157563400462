import { useState, useEffect } from 'react';
import { useParams, NavLink } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import DescriptionListItem from '../components/DescriptionListItem'
import Loading from '../components/Loading';
import Deleting from '../components/Deleting';
import ResourceNotFound from '../components/ResourceNotFound';
import PageHeader from '../components/PageHeader';

class toDisplay {
  constructor(oldContact, contactIfEmpty){ 
    const contact_with_italic_map = new Map();
    if (oldContact.length == 0) {
      contact_with_italic_map.set('contact',contactIfEmpty);
      contact_with_italic_map.set('italic',true);
    } 
      else {
        contact_with_italic_map.set('contact',oldContact);
        contact_with_italic_map.set('italic',false);
      }
    return contact_with_italic_map;
  }
}

const AccountRead = () => {
  const [accountDetail, setAccountDetail] = useState(null)
  const [accountNotFound, setAccountNotFound] = useState(null)
  const [accountDeleting, setAccountDeleting] = useState(null)

  // Get the "tag" parameter from the URL
  const { number } = useParams()

  // When the component renders for the first time, load the data from API
  useEffect(() => {
    const callAPI = () => {
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/accounts/${number}`,
        headers: { 'content-type': 'application/json' },
      }).then(response => {
        setAccountDetail(response.data)
      }).catch(error => {
        console.error('error', error)
        if (error.response && error.response.status === 404) {
          setAccountNotFound(error.response.data)
        }
      });
    }
    callAPI()
  }, [number])

  if (accountNotFound) {
    return <ResourceNotFound pageTitle="Account view" message={accountNotFound.Message} />
  }

  // React will try and render the component before the useEffect executes, so we need to handle
  // what to show when we don't yet have data to show
  if (accountDetail === null) {
    return <Loading pageTitle="Account view" />
  }

  if (accountDeleting) {
    const { status } = accountDeleting
    return <Deleting pageTitle={accountDetail.Name} inProgress={status === 'DELETING'} />
  }

  const poundIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>

  const securityIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
  </svg>

  const serviceIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
  </svg>

  const projectIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
  </svg>

  const seviceListIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
  </svg>

  const projects = accountDetail.Projects.map(proj => {
    return {
      label: proj.Name,
      url: `/projects/${proj.Tag}`
    }
  })

  const services = accountDetail.Services.map(svc => {
    return {
      label: svc.Name,
      url: `/services/${svc.Tag}`
    }
  })

  const convertStringToLabelObjForTable = item => {
    return { label: item }
  }

  const billingContacts = accountDetail.BillingContacts.map(convertStringToLabelObjForTable)
  const serviceContacts = accountDetail.ServiceContacts.map(convertStringToLabelObjForTable)
  const securityContacts = accountDetail.SecurityContacts.map(convertStringToLabelObjForTable)

  // owner field
  const projectOwners = accountDetail.ProjectOwners.map(convertStringToLabelObjForTable)

  const handleDeleteAccount = () => {
    setAccountDeleting({ status: 'DELETING' })
    axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/accounts/${number}`,
      headers: { 'content-type': 'application/json' },
    }).then(() => {
      setAccountDeleting({ status: 'DELETED' })
    }).catch(error => {
      console.error('error', error)
    })
  }

  const deleteAccount = () => {
    Swal.fire({
      title: 'Let me double check!',
      text: `Do you really want to delete ${accountDetail.Name}? This will also unlink it from any projects or services that use it.`,
      icon: 'warning',
      confirmButtonText: 'Delete',
      showCloseButton: true,
      buttonsStyling: false,
      allowEnterKey: false,
      customClass: {
        confirmButton: 'hover:bg-red-700 text-white px-3 py-2 rounded-md text-sm font-medium bg-red-500'
      }
    }).then(res => {
      const { isConfirmed } = res
      if (isConfirmed) {
        return handleDeleteAccount()
      }
    })
  }

  const deleteButton = (<button className="hover:bg-red-700 text-white px-3 py-2 rounded-md text-sm font-medium bg-red-500" onClick={deleteAccount}>
    Delete
  </button>)

  const editButton = (<NavLink className="hover:bg-indigo-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium bg-gray-100 block" to={`/accounts/edit/${accountDetail.Number}`}>
    Edit
  </NavLink>)

  const billingContactstoDisplay = new toDisplay(billingContacts,projectOwners);
  const serviceContactstoDisplay = new toDisplay(serviceContacts,projectOwners);
  const securityContactstoDisplay = new toDisplay(securityContacts,projectOwners);

  return (
    <>
      {/* Page Header */}
      <PageHeader title={accountDetail.Name} buttons={[editButton, deleteButton]} />


      {/* Container */}
      <div className="container mt-10 flex">
        <div className="w-1/3">
          <div className="pr-5">
            <div className="text-lg font-bold tracking-normal text-gray-900">Account information</div>
            <div className="text-gray-600 mt-1">This is all the information about the account. You can click through to connected accounts and the parent project.</div>
          </div>
        </div>
        {/* Form in white box */}
        <div className="w-2/3 bg-white rounded-lg box-border shadow-md">
          <DescriptionListItem label="Account name:" value={accountDetail.Name} />
          <DescriptionListItem label="Account number:" value={accountDetail.Number} />
          <DescriptionListItem label="Scope:" value={accountDetail.Scope} />
          <DescriptionListItem label="Status:" value={accountDetail.Status} />
          <DescriptionListItem label="Environment:" value={accountDetail.Environment} />
          <DescriptionListItem label="Billing contacts:" value={{ type: "table", icon: poundIcon, items: billingContactstoDisplay.get('contact'), italic: billingContactstoDisplay.get('italic')}} />
          <DescriptionListItem label="Security contacts:" value={{ type: "table", icon: securityIcon, items: securityContactstoDisplay.get('contact'), italic: securityContactstoDisplay.get('italic')}} />
          <DescriptionListItem label="Service contacts:" value={{ type: "table", icon: serviceIcon, items: serviceContactstoDisplay.get('contact'), italic: serviceContactstoDisplay.get('italic')}} />
          <DescriptionListItem label="Projects:" value={{ type: "table", icon: projectIcon, items: projects }} />
          <DescriptionListItem label="Services:" value={{ type: "table", icon: seviceListIcon, items: services }} />
          <DescriptionListItem label="Project Owner:" value={{ type: "table", icon: securityIcon, items: projectOwners }} />
        
        </div>
      </div>
    </>
  );
}

export default AccountRead;