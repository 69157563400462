import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Fuse from 'fuse.js';
import axios from 'axios';
import PageHeader from '../components/PageHeader';
import StackedListItem from '../components/StackedListItem';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';

const ServiceList = props => {
  const [services, setServices] = useState(null)
  const [visibleServices, setVisibleServices] = useState(null)
  const [serviceSearch, setServiceSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 6

  const updateServiceSearchFromForm = (event) => {
    setServiceSearch(event.currentTarget.value)
  }

  useEffect(() => {
    getServices()
  }, [])

  useEffect(() => {
    if (services === null) {
      return;
    }

    if (serviceSearch.length < 3) {
      setVisibleServices(services)
      return;
    }

    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      minMatchCharLength: 3,
      // location: 0,
      threshold: 0.3,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys: [
        "Name",
      ]
    };
    
    const fuse = new Fuse(services, options);
    const results = fuse.search(serviceSearch)
    const mappedResults = results.map(item => item.item)
    setVisibleServices(mappedResults)
    setCurrentPage(1)
  }, [serviceSearch, services])

  const getServices = () => {
    axios({
      method: 'GET',
      url:`${process.env.REACT_APP_API_BASE_URL}/services`,
      headers: { 'content-type': 'application/json' },
    }).then(response => {
        setServices(response.data.Services)
        setVisibleServices(response.data.Services)
    })
  }

  if (visibleServices === null) {
    return <Loading pageTitle="Services" />
  }

   // Get projects based on pagination
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = visibleServices.slice(indexOfFirstPost, indexOfLastPost)

  const serviceListItems = currentPosts.map((service, key) => {
    return <StackedListItem key={key} label={service.Name} linkTo={`services/${service.Tag}`} />
  })

  const serviceList = (
    <ul className="w-full max-w-md bg-white rounded-lg box-border shadow-md">
      { visibleServices.length > 0 ? serviceListItems : <StackedListItem label={"No services"} /> }
    </ul>
  )

  const totalPages = Math.max(1, Math.ceil(visibleServices.length/postsPerPage))

  const increasePage = () => {
    if (currentPage+1 <= totalPages) {
      setCurrentPage(currentPage+1);
    }
  }

  const decreasePage = () => {
    if (currentPage-1 > 0) {
      setCurrentPage(currentPage-1);
    }
      
  }

  const addButton = (<NavLink className="hover:bg-indigo-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium bg-gray-100 block" to="/services/create">
    Add service
  </NavLink>)

  return (
    <>
        {/* Page Header */}
        <PageHeader title="Services" buttons={[addButton]}/>

        {/* Search bar */}
        <div className="container mt-5">
          <label className="block" htmlFor="servicesearch">Search bar</label>
          <input type="text" className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" onChange={updateServiceSearchFromForm} id="servicesearch" placeholder="Enter service name" value={serviceSearch} />
        </div>

        {/* Container */}
        <div className="container mt-10">
          <div className="flex flex-col items-center max-w-md">
            {serviceList}          
            <Pagination decreasePage={decreasePage} increasePage={increasePage} currentPage={currentPage} totalPages={totalPages} />
          </div>
        </div> 
    </>
  );
}

export default ServiceList; 