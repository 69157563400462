import { useParams } from 'react-router-dom'
import EmailQuery from './EmailQuery'

const DynamicQueries = props => {
  const { query } = useParams()

  const map = {
    'entities-by-email': EmailQuery
  }

  const Component = map[query]
  return <Component {...props} />
}

export default DynamicQueries;