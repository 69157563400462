import React from 'react'
import { NavLink } from 'react-router-dom'
import "./css/StackedListItem.css";

const StackedListItem = props => {
  return (
    <li className="justify-between border-gray-200 border-b border-solid last:border-b-0 hover:bg-gray-200">
      { props.linkTo ?
        <NavLink to={props.linkTo} className="p-5 flex items-center justify-between">
          <div>{ props.label }</div>
            <div className="h-5 w-5">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
        </NavLink> 
        : 
        <div className="p-5 flex items-center justify-between">{ props.label }</div>
      }
    </li>
  )
}

export default StackedListItem


// import React from 'react'
// import { NavLink } from 'react-router-dom'
// import "./css/StackedListItem.css";

// const StackedListItem = props => {
//   return (
//     <li className=" hover:bg-gray-200 hover:rounded-lg border-gray-300 border-b border-solid last:border-b-0">
//     <NavLink to={props.linkTo} className="p-5 flex items-center justify-between">
//       <div>{ props.label }</div>
//       { props.linkTo && 
//         <div className="h-5 w-5">
//           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
//           </svg>
//         </div>
//       }
//     </NavLink>
//     </li>
    
//   )
// }

// export default StackedListItem
