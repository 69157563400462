import { useState } from 'react';
import axios from 'axios';
import PageHeader from '../components/PageHeader';
import InformationModal from '../components/InformationModal';
import ButtonLoadingSpinner from '../components/ButtonLoadingSpinner';
import { Text, Label, MultiText, RadioGroup } from '../components/Form/index';

const ProjectCreate = props => {
  const [apiCallInProgress, setApiCallInProgress] = useState(false)
  const [projectName, setProjectName] = useState("")
  const [billingReference, setBillingReference] = useState("")
  const [billingContacts, setBillingContacts] = useState([])
  const [securityContacts, setSecurityContacts] = useState([])
  const [serviceContacts, setServiceContacts] = useState([])
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessages, setErrorMessages] = useState([])
  const [projectTag, setProjectTag] = useState("")
  const [projectStatus, setProjectStatus] = useState("")
  const [projectOwner, setProjectOwner] = useState([])

  const updateProjectNameFromForm = (event) => {
    setProjectName(event.currentTarget.value)
  }

  const updateBillingReferenceFromForm = (event) => {
    setBillingReference(event.currentTarget.value)
  }

  const updateAccountBillingContacts = val => {
    setBillingContacts(val)
  }

  const updateAccountSecurityContacts = val => {
    setSecurityContacts(val)
  }

  const updateAccountServiceContacts = val => {
    setServiceContacts(val)
  }

  const updateProjectOwner = val => {
    setProjectOwner(val)
  }

  const updateProjectTag = (event) => {
    setProjectTag(event.currentTarget.value)
  }

  const updateProjectStatus = event => {
    setProjectStatus(event.currentTarget.value)
  } 

  const callAPI = () => {
    setApiCallInProgress(true)

    // using built in JSON utility package turn object to string and store in a variable
    const body = {
      "projectname": projectName,
      "billingref": billingReference,
      "billingemail": billingContacts,
      "securityemail": securityContacts,
      "serviceemail": serviceContacts,
      "projecttag": projectTag,
      "projectstatus": projectStatus,
      "projectowner": projectOwner
    };

    // make API call with parameters and use promises to get response
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/projects`,
      data: JSON.stringify(body),
      headers: { 'content-type': 'application/json' },
    }).then(response => {
      setErrorMessages([])
      setApiCallInProgress(false)
      setSuccessMessage(response.data.Message)
    }).catch(error => {
      const tempErrorMessages = []
      const fieldNameMap = {
        projectname: "Project name",
        billingref: "Billing reference",
        billingemail: "Billing contact email",
        securityemail: "Security contact email",
        serviceemail: "Service contact email",
        projecttag: "Project tag",
        projectstatus: "Project status",
        projectowner: "Project owner"
      }
      for (const [field, messages] of Object.entries(error.response.data.errors)) {
        const mappedField = fieldNameMap[field]
        for (const msg of messages) {
          tempErrorMessages.push(`${mappedField}: ${msg}`)
        }
      }
      setApiCallInProgress(false)
      setErrorMessages(tempErrorMessages)
    });
  }

  // const inheritOwnerCheckAPI = () => {
  //   if (isNotEmpty(projectOwner)) {
  //     if (isEmpty(billingContacts)) {
  //       updateAccountBillingContacts(projectOwner);
  //     }
  //     if (isEmpty(securityContacts)) {
  //       updateAccountSecurityContacts(projectOwner);
  //     }
  //     if (isEmpty(serviceContacts)) {
  //       updateAccountServiceContacts(projectOwner);
  //     }
  //   }

  //   setTimeout(function() {callAPI();}, 1000);
  // }

  const renderedErrorMessages = errorMessages.map((msg, key) => <div key={key}>{msg}</div>)

  const statusOptions = [
    {'id': 'status_active', 'value': 'Active', 'label': 'Active'},
    {'id': 'status_suspended', 'value': 'Suspended', 'label': 'Suspended'},
    {'id': 'status_closed', 'value': 'Closed', 'label': 'Closed'},
    {'id': 'status_terminated', 'value': 'Terminated', 'label': 'Terminated'},
    {'id': 'status_pending', 'value': 'Pending', 'label': 'Pending'},          
  ]
  return (
    <>
      {/* Page Header */}
      <PageHeader title="Create new project" />

      {/* Container */}
      <div className="container mt-10 flex">
        <div className="w-1/3">
          <div className="pr-5">
            <div className="text-lg font-bold tracking-normal text-gray-900">Project information</div>
            <div className="text-gray-600 mt-1">This information will provide important context around your project running in the cloud.</div>
          </div>
        </div>
        {/* Form in white box */}
        <div className="w-2/3 bg-white rounded-lg box-border shadow-md">
          {/* SuccessMessage */}
          <div className="p-8">
            {successMessage && (
              <div className="rounded bg-green-500 text-white text-base p-4 mb-5">
                {successMessage}
              </div>
            )}
            {errorMessages.length > 0 && (
              <div className="rounded bg-red-500 text-white text-base p-4 mb-5">
                {renderedErrorMessages}
              </div>
            )}

            {/* Project Name */}
            <div className="mb-5">
              <label className="block flex items-center" htmlFor="projectname">Project name:<div className="mx-1"><InformationModal title="project name" text="the project name is the name of the project, it can be changed after creation."/></div></label>
              <input type="text" className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" onChange={updateProjectNameFromForm} id="projectname" placeholder="Enter project name" value={projectName} />
            </div>

            {/* Billing Reference */}
            <div className="mb-5">
              <label className="block flex items-center" htmlFor="billingref">Billing reference: <div className="mx-1"><InformationModal title="Billing Reference" text="the billing reference is #####, it can be changed after creation."/></div></label>
              <input type="text" onChange={updateBillingReferenceFromForm} className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" id="billingref" placeholder="Enter billing reference" value={billingReference} />
            </div>

            {/* Billing contacts */}
            <div>
              <Label text="Billing contacts" />
              <MultiText value={billingContacts} onChange={updateAccountBillingContacts} />
            </div>

            {/* Security contacts */}
            <div>
              <Label text="Security contacts" />
              <MultiText value={securityContacts} onChange={updateAccountSecurityContacts} />
            </div>

            {/* Service contacts */}
            <div>
              <Label text="Service contacts" />
              <MultiText value={serviceContacts} onChange={updateAccountServiceContacts} />
            </div>

            {/* Project Status */}
            <div className="mb-5"/>        
                <Label text="Project Status" />
                <RadioGroup name="projectstatus" options={statusOptions} onSelect={updateProjectStatus} />

            {/* Project Tag */}
            <div className="mb-5">
              <label className="block flex items-center" htmlFor="servicetag">Project tag: <div className="mx-1"><InformationModal title="project tag" text="the project tag is a unique name for the project, it can not be changed after creation."/></div> </label>
              <input onChange={updateProjectTag} type="text" className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" id="projecttag" value={projectTag} placeholder="Enter project tag" />
            </div>

            {/* Owner Field */}
            <div>
              <div className="flex items-center">
                <Label text="Project Owner" />
                  <div className="mx-1">
                  <InformationModal 
                      title="Project Owner" 
                      text="The project owner would be responsible for this project. 
                      Additionally, who is responsible for the accounts added to this project"/>
                  </div>
                </div>
              <MultiText value={projectOwner} onChange={updateProjectOwner} />
            </div>

          </div>

          <div className="bg-gray-200 rounded-bl rounded-br flex justify-end py-3 px-8">
            <button className="bg-primary text-white rounded py-2 px-5 flex items-center" type="button" onClick={callAPI}>
              <div>Add project</div>
              {apiCallInProgress && <ButtonLoadingSpinner />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectCreate;

