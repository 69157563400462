import { useState, useEffect } from 'react';
import axios from 'axios';
import PageHeader from '../components/PageHeader'
import ButtonLoadingSpinner from '../components/ButtonLoadingSpinner'
import Select from '../components/Select'
import MultiSelect from '../components/MultiSelect'
import InformationModal from '../components/InformationModal';
import { Text, Label, MultiText, RadioGroup } from '../components/Form/index';

const ServiceCreate = props => {
  const [apiCallInProgress, setApiCallInProgress] = useState(false)
  const [serviceName, setServiceName] = useState("")
  const [projects, setProjects] = useState([])
  const [projectsForSelection, setProjectsForSelection] = useState([])
  const [accountNumbers, setAccountNumbers] = useState([])
  const [accountNumbersForSelection, setAccountNumbersForSelection] = useState([])
  const [projectName, setProjectName] = useState("")
  const [billingContacts, setBillingContacts] = useState([])
  const [securityContacts, setSecurityContacts] = useState([])
  const [serviceContacts, setServiceContacts] = useState([])
  const [serviceTag, setServiceTag] = useState("")
  const [serviceStatus, setServiceStatus] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessages, setErrorMessages] = useState([])

  const updateProjectTagFromForm = item => {
    setProjectName(item)
  }

  const updateServiceNameFromForm = (event) => {
    setServiceName(event.currentTarget.value)
  }

  const updateAccountBillingContacts = val => {
    setBillingContacts(val)
  }

  const updateAccountSecurityContacts = val => {
    setSecurityContacts(val)
  }

  const updateAccountServiceContacts = val => {
    setServiceContacts(val)
  }

  const updateServiceTag = (event) => {
    setServiceTag(event.currentTarget.value)
  }

  const updateAccountNumbers = (item, wasSelected) => {
    let copyOfAccountNumbers = [...accountNumbers]
    if (wasSelected) {
      // If it's been clicked and it WAS selected, it should now be unselected
      copyOfAccountNumbers = copyOfAccountNumbers.filter(acct => acct.value !== item.value)
    } else {
      copyOfAccountNumbers.push(item)
    }

    setAccountNumbers(copyOfAccountNumbers)
  }

  const updateServiceStatus = (event) => {
    setServiceStatus(event.currentTarget.value)
  }

  useEffect(() => {
    // Get the list of projects for the dropdown
    const getProjects = () => {
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/projects`,
        headers: { 'content-type': 'application/json' },
      }).then(response => {
        const formattedProjects = response.data.Projects.map(proj => {
          return { label: proj.Tag, value: proj.Tag }
        })
        setProjectsForSelection(formattedProjects)
        setProjects(response.data.Projects)
      })
    }

    getProjects()
  }, [])

  useEffect(() => {
    const project = projects.find(p => p.Tag === projectName.value)
    if (project) {
      const accounts = project.Accounts.map(a => { return {label: a, value: a} })
      setAccountNumbersForSelection(accounts)
    }
  }, [projectName, projects])

  const createService = () => {
    setApiCallInProgress(true)
    const body = {
      "servicename": serviceName,
      "projecttag": projectName.value ? projectName.value : "",
      "billingemail": billingContacts,
      "securityemail": securityContacts,
      "serviceemail": serviceContacts,
      "servicetag": serviceTag,
      "accountNumberOutput": accountNumbers.map(acct => ({number: acct.value})),
      "servicestatus": serviceStatus
    };

    // make API call with parameters and use promises to get response
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/services`,
      data: JSON.stringify(body),
      headers: { 'content-type': 'application/json' },
    }).then(response => {
      setErrorMessages([])
      setApiCallInProgress(false)
      setSuccessMessage(response.data.Message)
    }).catch(error => {
      const tempErrorMessages = []
      const fieldNameMap = {
        servicename: "Service name",
        projecttag: "Project tag",
        billingemail: "Billing contact email",
        securityemail: "Security contact email",
        serviceemail: "Service contact email",
        accountNumberOutput: "Account numbers",
        servicetag: "Service tag",
        servicestatus: "Service Status"
      }
      for (const [field, messages] of Object.entries(error.response.data.errors)) {
        const mappedField = fieldNameMap[field]
        for (const msg of messages) {
          tempErrorMessages.push(`${mappedField}: ${msg}`)
        }
      }
      setErrorMessages(tempErrorMessages)
      setApiCallInProgress(false)
    });
  }

  const renderedErrorMessages = errorMessages.map((msg, key) => <div key={key}>{msg}</div>)

  const statusOptions = [
    {'id': 'status_prototype', 'value': 'Prototype', 'label': 'Prototype'},
    {'id': 'status_dev', 'value': 'Dev', 'label': 'Dev'},
    {'id': 'status_preprod', 'value': 'Preprod', 'label': 'Preprod'},
    {'id': 'status_live', 'value': 'Live', 'label': 'Live'},
    {'id': 'status_suspended', 'value': 'Suspended', 'label': 'Suspended'},
  ]

  return (
    <>
      {/* Page Header */}
      <PageHeader title="Create new service" />

      {/* Container */}
      <div className="container mt-10 flex">
        <div className="w-1/3">
          <div className="pr-5">
            <div className="text-lg font-bold tracking-normal text-gray-900">Service information</div>
            <div className="text-gray-600 mt-1">This information will provide important context around your project running in the cloud.</div>
          </div>
        </div>
        {/* Form in white box */}
        <div className="w-2/3 bg-white rounded-lg box-border shadow-md">
          {/* SuccessMessage */}
          <div className="p-8">
            {successMessage && (
              <div className="rounded bg-green-500 text-white text-base p-4 mb-5">
                {successMessage}
              </div>
            )}
            {errorMessages.length > 0 && (
              <div className="rounded bg-red-500 text-white text-base p-4 mb-5">
                {renderedErrorMessages}
              </div>
            )}

            {/* Service Name */}
            <div className="mb-5">
              <label className="block" htmlFor="servicename">Service name:</label>
              <input type="text" className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" onChange={updateServiceNameFromForm} id="servicename" placeholder="Enter service name" value={serviceName} />
            </div>

            {/* Service Status */}
            <div className="py-3"/>
              <Label text="Service Status" />
              <RadioGroup name="servicestatus" options={statusOptions} onSelect={updateServiceStatus} />
              
            {/* Project Name */}
            <div className="mb-5">
              {/* <input type="text" className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" onChange={updateProjectNameFromForm} id="projecttag" placeholder="Enter project name" value={projectName} /> */}
              <label className="block" htmlFor="projectname">Project Tag:</label>
              <Select
                placeholderText="Choose a project"
                selected={projectName}
                onSelect={updateProjectTagFromForm}
                items={projectsForSelection}
              />
            </div>

            {/* Billing contacts */}
            <div>
              <Label text="Billing contacts" />
              <MultiText value={billingContacts} onChange={updateAccountBillingContacts} />
            </div>

            {/* Security contacts */}
            <div>
              <Label text="Security contacts" />
              <MultiText value={securityContacts} onChange={updateAccountSecurityContacts} />
            </div>

            {/* Service contacts */}
            <div>
              <Label text="Service contacts" />
              <MultiText value={serviceContacts} onChange={updateAccountServiceContacts} />
            </div>

            {/* Service Tag */}
            <div className="mb-5">
              <label className="block flex items-center" htmlFor="servicetag">Service tag:<div className="mx-1"> <InformationModal title="service tag" text="the service tag is a unique name for the project, it can not be changed after creation."/></div></label>
              <input onChange={updateServiceTag} type="text" className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" id="servicetag" value={serviceTag} placeholder="Enter service tag" />
            </div>


            {/* Account Numbers */}
            <div className="mb-4">
            <div className="flex items-center">
              <label htmlFor="account-number">Account numbers:</label>
              <div className="mx-1"><InformationModal title="Account numbers" text="These are the account number you would like to have associated with this service, it can be altered after creation."/></div>
              </div>
              <MultiSelect
                placeholderText="Choose accounts from the project"
                selected={accountNumbers}
                onOptionClick={updateAccountNumbers}
                items={accountNumbersForSelection}
              />
            
            </div>
          </div>

          <div className="bg-gray-200 rounded-bl rounded-br flex justify-end py-3 px-8">
            <button className="bg-primary text-white rounded py-2 px-5 flex items-center" type="button" onClick={createService}>
              <div>Add Service</div>
              {apiCallInProgress && <ButtonLoadingSpinner />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCreate;

