import {
  Link
} from "react-router-dom";

const DescriptionListItemBase = props => {
  return (
    <div className="px-4 py-5 border-b border-gray-200 border-solid last:border-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">
        {props.label}
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {props.url ? <Link to={props.url}>{props.value}</Link> : <div>{props.value}</div>}
      </dd>
    </div>
  )
}

export default DescriptionListItemBase;