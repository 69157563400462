import React from 'react'

const RadioOption = props => {
  const { id, name, value, label, onClick } = props

  return (<div className="flex items-center justify-start">
    <input onClick={onClick} id={id} name={name} type="radio" value={value} className="focus:ring-primary h-4 w-4 text-primary border-gray-300" />
    <label htmlFor={id} className="ml-3 block text-sm font-medium text-gray-600">
      {label}
    </label>
  </div>)
}

/**
 * The group of radio buttons
 * 
 * Props:
 *   - name: the name of the group of radio buttons, maps to the HTML 'name' property
 *   - options: an array of options in the format [{id: '', value: '', label: ''}, ...]
 *   - onSelect: a function that can handle the onClick event of a radio button
 */
const RadioGroup = props => {
  const { name, options, onSelect } = props

  const renderedOptions = options.map((opt, key) => {
    return <RadioOption key={key} onClick={onSelect} id={opt.id} name={name} value={opt.value} label={opt.label} />
  })

  return <div className="space-y-2">{renderedOptions}</div>
}

export default RadioGroup
