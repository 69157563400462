import {
  Link
} from "react-router-dom";

const DescriptionListItemTable = props => {
  const table = props.items.map((item, key) => {
    if (props.italic == true) {
        return <div key={key} className="px-4 py-4 border-b border-gray-200 border-solid last:border-0 flex items-center">
        {props.icon && (<div className="h-5 w-5 mr-3 text-gray-400">{props.icon}</div>)}
        {item.url ? <div className="text-sm italic text-gray-400"><Link to={item.url}>{item.label}</Link></div> : <div className="text-sm italic text-gray-400">{item.label}</div>}
      </div>
    } else {
      return <div key={key} className="px-4 py-4 border-b border-gray-200 border-solid last:border-0 flex items-center">
      {props.icon && (<div className="h-5 w-5 mr-3 text-gray-400">{props.icon}</div>)}
      {item.url ? <div className="text-sm"><Link to={item.url}>{item.label}</Link></div> : <div className="text-sm">{item.label}</div>}
    </div>
    }
  })
  return (
    <div>
      { props.description && <div className="mb-4">{props.description}</div>}
      <div className="rounded-lg border border-solid border-gray-200 max-w-sm">
        {props.items.length > 0 ? table : <div className="p-4">No items found</div>}
      </div>
    </div>
  )
}

export default DescriptionListItemTable;