import { Link } from 'react-router-dom'
import PageHeader from '../components/PageHeader';

const Queries = props => {
  const availableQueries = [
    { label: 'Find account, project or service by email address', url: 'entities-by-email' }
  ]

  const output = availableQueries.map(q => {
    const link = `/queries/${q.url}`
    return <Link to={link} className="bg-white rounded-lg box-border shadow-md block p-5 hover:bg-primary text-black hover:text-white hover:cursor-pointer">{q.label}</Link>
  })

  return (
    <>
      {/* Page Header */}
      <PageHeader title="Queries" />
      <div className="container mt-10">
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 gap-4">
          {output}
        </div>
      </div>
    </>
  );
}

export default Queries; 