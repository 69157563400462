import React, { useContext } from 'react'
import { UserAuthContext } from "../App"
import { Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import btLogo from '../bt_logo_dark.svg'

const Login = () => {
  const authContext = useContext(UserAuthContext)
  const authenticated = authContext.session && authContext.user

  if (authenticated) {
    return <Redirect to="/" />
  }

  return (
    <div className="pt-10">
      <div className="mx-auto max-w-sm bg-white rounded-lg shadow-md p-8 flex flex-col items-center">
        <div>
          <img src={btLogo} className="h-36" alt="BT Logo" />
        </div>
        <div className="text-xl mb-8 mt-4">
          Cloud Service Inventory
      </div>
        <button
          className="hover:bg-indigo-600 bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium text-white"
          onClick={() => Auth.federatedSignIn({ provider: process.env.REACT_APP_COGNITO_USER_POOL_SSO_IDP_NAME })}
        >
          Login with AWS SSO
      </button>
      </div>
    </div>
  )
}

export default Login
