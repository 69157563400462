import { useState } from 'react';
import axios from 'axios';
import PageHeader from '../components/PageHeader'
import DescriptionListItem from '../components/DescriptionListItem'
import ButtonLoadingSpinner from '../components/ButtonLoadingSpinner'

const EmailQuery = () => {
  const [emailQuery, setEmailQuery] = useState("")
  const [queryDetail, setQueryDetail] = useState(null)
  const [apiCallInProgress, setApiCallInProgress] = useState(false)

  const updateEmailQueryFromForm = (event) => {
    setEmailQuery(event.currentTarget.value)
  }

  // Call the API, searching for projects, services or accounts that the email matches
  const search = () => {
    setApiCallInProgress(true)
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/queries/entities-by-email/${emailQuery}`,
      headers: { 'content-type': 'application/json' },
    }).then(response => {
      setQueryDetail(response.data)
      setApiCallInProgress(false)
    }).catch(error => {
      console.error('error', error)
      setApiCallInProgress(false)
    });
  }

  const convertToFormatForTable = item => {
    const types = item['ContactTypes'].join(', ')
    const str = `${item['Name']} (${types})`
    return { label: str }
  }

  let elements = null
  if (queryDetail !== null) {
    const projects = queryDetail.Projects.map(convertToFormatForTable)
    const accounts = queryDetail.Accounts.map(convertToFormatForTable)
    const services = queryDetail.Services.map(convertToFormatForTable)
    elements = (
      <div className="bg-white rounded-lg box-border shadow-md">
        <DescriptionListItem label="Projects:" value={{ type: "table", items: projects.length > 0 ? projects : [{label: 'No projects found'}] }} />
        <DescriptionListItem label="Accounts:" value={{ type: "table", items: accounts.length > 0 ? accounts : [{label: 'No accounts found'}] }} />
        <DescriptionListItem label="Services:" value={{ type: "table", items: services.length > 0 ? services : [{label: 'No services found'}] }} />
      </div>
    )
  }

return (
  <>
    {/* Page Header */}
    <PageHeader title="Find entity by email" />

    {/* Container */}
    <div className="container mt-10 flex">
      <div className="w-1/3">
        <div className="pr-5">
          {/* Search bar */}
          <div className="flex justify-start items-center">
            <input type="text" className="outline-none shadow-md rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" onChange={updateEmailQueryFromForm} id="emailquery" placeholder="Enter email to search for" value={emailQuery} />
            <button className="hover:bg-purple-600 bg-primary px-3 py-2 rounded-md text-sm text-white font-medium ml-2 flex items-center" onClick={search}><span className="block">Search</span> {apiCallInProgress && <ButtonLoadingSpinner />}</button>
          </div>
          <div className="text-lg font-bold tracking-normal text-gray-900 mt-5">Query</div>
          <div className="text-gray-600 mt-1">Find all projects, services and accounts running in the cloud related to the email searched for.</div>
        </div>
      </div>
      <div className="w-2/3">
        {/* Show the entities found, or nothing if query not made yet */}
        {elements}
      </div>
    </div>
  </>
);
}

export default EmailQuery; 