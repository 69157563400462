import React, { useState, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify';
import axios from 'axios';
import {
  Switch,
  Route,
  NavLink,
  withRouter,
  useLocation
} from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute"
import ProjectList from "./pages/ProjectList"
import ProjectRead from "./pages/ProjectRead"
import ProjectCreate from "./pages/ProjectCreate"
import ServiceList from './pages/ServiceList'
import ServiceRead from "./pages/ServiceRead"
import ServiceEdit from "./pages/ServiceEdit"
import ServiceCreate from "./pages/ServiceCreate"
import AccountRead from "./pages/AccountRead"
import AccountEdit from './pages/AccountEdit';
import AccountList from './pages/AccountList'
import DynamicQueries from './pages/DynamicQueries'
import Queries from './pages/Queries'
import Login from './pages/Login';
import ProjectEdit from './pages/ProjectEdit'
import HomeRead from './pages/HomeRead'

import './App.css';
import btLogo from './bt_logo.svg';
import AccountCreate from './pages/AccountCreate';

// Set up context for keeping track of user authentication
const UserAuthContext = React.createContext(null)

const App = () => {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  const location = useLocation()

  useEffect(() => {
    getUser().then(data => setUser(data))
    getSession().then(data => {
      setSession(data)
      if (data) {
        const token = data.getIdToken().getJwtToken()
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      }
    })
  }, [location])

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break;
      }
    });

    const setupInitialAuthState = async () => {
      const userData = await getUser()
      const sessionData = await getSession()
  
      setUser(userData)
      setSession(sessionData)
      setAuthLoading(false)
    }

    setupInitialAuthState()
  }, []);

  const getSession = async () => {
    try {
      const session = await Auth.currentSession()
      return session
    } catch (e) {
      console.error(e)
      return null;
    }
  }

  const getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser({ bypassCache: true });
      // const attributes = userData.signInUserSession.idToken.payload;
      return userData;
    } catch (e) {
      console.error('Not signed in');
      return null;
    }
  }

  const routes = (
    <UserAuthContext.Provider value={{ user, session }}>
      <Switch>
        <PrivateRoute component={ProjectEdit} path="/projects/edit/:tag" />
        <PrivateRoute component={ProjectCreate} path="/projects/create" />
        <PrivateRoute component={ProjectRead} path="/projects/:tag" />
        <PrivateRoute component={ProjectList} exact path="/projects" />

        <PrivateRoute component={ServiceEdit} exact path="/services/edit/:tag" />
        <PrivateRoute component={ServiceCreate} path="/services/create" />
        <PrivateRoute component={ServiceRead} path="/services/:tag" />
        <PrivateRoute component={ServiceList} exact path="/services" />
        
        <PrivateRoute component={AccountCreate} exact path="/accounts/create" />
        <PrivateRoute component={AccountEdit} path="/accounts/edit/:number" />
        <PrivateRoute component={AccountRead} path="/accounts/:number" />
        <PrivateRoute component={AccountList} exact path="/accounts" />

        <PrivateRoute component={DynamicQueries} path="/queries/:query"/>
        <PrivateRoute component={Queries} exact path="/queries"/>

        <PrivateRoute component={HomeRead} exact path="/"/>

        <Route component={Login} exact path="/login" />
        
        {/* <PrivateRoute component={() => <div>Home</div>} exact path="/" /> */}
      </Switch>
    </UserAuthContext.Provider>
  )

  const isNotLoginPage = location.pathname.match('^/login/?$') === null

  return (
    <div className="bg-gray-100 min-h-screen font-sans">
      {/* Header */}
      {!authLoading && isNotLoginPage && <div className="w-full bg-primary py-3 pl-3 pr-6 h-16 flex items-center text-white justify-between">
        <div className="flex items-center">
          <div className="flex items-center">
            <img src={btLogo} alt="BT logo" className="h-16" />
            <div className="ml-4 text-lg">Cloud inventory</div>
            <div className="hover:bg-red-600 bg-red-700 px-2 py-2 mx-2 rounded-md text-sm font-medium">{process.env.REACT_APP_Stage_Name}</div> {/* Development/Demo/Live */}
          </div>
          <nav className="ml-10 flex items-baseline space-x-4">
            <NavLink exact activeClassName="bg-indigo-900" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/">Home</NavLink>
            <NavLink exact activeClassName="bg-indigo-900" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/projects">Projects</NavLink>
            <NavLink exact activeClassName="bg-indigo-900" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/services">Services</NavLink>
            <NavLink exact activeClassName="bg-indigo-900" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/accounts">Accounts</NavLink>
            <NavLink exact activeClassName="bg-indigo-900" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" to="/queries">Queries</NavLink>
          </nav>
        </div>
        <div className="space-x-4">
        <form className="inline-block" action="https://forms.office.com/r/qBJjBTJRKG">
          <input type="submit" className="cursor-pointer hover:bg-indigo-600 bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" value="Feedback" />
        </form>
        {user ? (
          <button className="hover:bg-indigo-600 bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium" onClick={() => Auth.signOut()}>Logout</button>
        ) : (
          <button
            className="incline-block hover:bg-indigo-600 bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium"
            onClick={() => Auth.federatedSignIn({ provider: process.env.REACT_APP_COGNITO_USER_POOL_SSO_IDP_NAME })}
          >
            Login with AWS SSO
          </button>
        )}
        </div>
      </div>}
      { authLoading ? null : routes}
    </div>
  )
}

export default withRouter(App);
export { UserAuthContext };

