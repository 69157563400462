import React, { useState } from 'react'
import Text from './Text'

const deleteIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg>

const MultiText = props => {
  const { value, onChange } = props
  const [innerState, setInnerState] = useState(value)

  // Handle the change, updating the inner state and then bubbling up the overall change to
  // the parent component via onChange prop
  const handleChange = event => {
    const index = parseInt(event.currentTarget.name)
    const tempState = [...innerState]
    tempState[index] = event.currentTarget.value
    setInnerState(tempState)
    onChange(tempState)
  }

  // Add a new empty input field for filling in
  const addInput = () => {
    const tempState = [...innerState]
    tempState.push("")
    setInnerState(tempState)
  }
  
  // Given an index, remove that element from the innerState then bubble up to parent
  const removeInput = (event) => {
    const indexNum = parseInt(event.currentTarget.dataset.key)
    const tempState = [...innerState]
    tempState.splice(indexNum, 1)
    setInnerState(tempState)
    onChange(tempState)
  }

  const inputs = innerState.map((v, key) => <div key={key} className="mb-2 flex items-center">
    <Text value={v} onChange={handleChange} name={key} />
    <button
      onClick={removeInput}
      type="button"
      className="w-5 h-5 ml-2"
      data-key={key}
    >
      {deleteIcon}
    </button>
  </div>)

  return <div>
    {inputs}
    <button onClick={addInput} type="button" className="mt-2 bg-gray-200 text-xs border-gray-800 text-black rounded py-1 px-3">Add</button>
  </div>
}

export default MultiText
