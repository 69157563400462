import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Fuse from 'fuse.js';
import PageHeader from '../components/PageHeader';
import StackedListItem from '../components/StackedListItem';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';

const ProjectList = props => {
  const [projects, setProjects] = useState(null)
  const [visibleProjects, setVisibleProjects] = useState(null)
  const [projectSearch, setProjectSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 6

  const updateProjectSearchFromForm = (event) => {
    setProjectSearch(event.currentTarget.value)
  }

  useEffect(() => {
    getProjects()
  }, [])

  useEffect(() => {
    if (projects === null) {
      return;
    }

    if (projectSearch.length < 3) {
      setVisibleProjects(projects)
      return;
    }

    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      minMatchCharLength: 3,
      // location: 0,
      threshold: 0.3,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys: [
        "Name",
      ]
    };
    
    const fuse = new Fuse(projects, options);
    const results = fuse.search(projectSearch)
    const mappedResults = results.map(item => item.item)
    setVisibleProjects(mappedResults)
    setCurrentPage(1)
  }, [projectSearch, projects])

  const getProjects = () => {
    axios({
      method: 'GET',
      url:`${process.env.REACT_APP_API_BASE_URL}/projects`,
      headers: { 'content-type': 'application/json' },
    }).then(response => {
        setProjects(response.data.Projects)
        setVisibleProjects(response.data.Projects)
    })
  }

  if (visibleProjects === null) {
    return <Loading pageTitle="Projects" />
  }

  // Get projects based on pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = visibleProjects.slice(indexOfFirstPost, indexOfLastPost)

  
  const projectListItems = currentPosts.map((project, key) => {
    return <StackedListItem key={key} label={project.Name} linkTo={`projects/${project.Tag}`} />
  })

  const projectList = (
    <ul className="w-full max-w-md bg-white rounded-lg box-border shadow-md">
      { visibleProjects.length > 0 ? projectListItems : <StackedListItem label={"No projects"} /> }
    </ul>
  )

  const totalPages = Math.max(1, Math.ceil(visibleProjects.length/postsPerPage))

  const increasePage = () => {
    if (currentPage+1 <= totalPages) {
      setCurrentPage(currentPage+1);
    }
  }

  const decreasePage = () => {
    if (currentPage-1 > 0) {
      setCurrentPage(currentPage-1);
    }
      
  }

  const addButton = (<NavLink className="hover:bg-indigo-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium bg-gray-100 block" to={`/projects/create`}>
    Add project
  </NavLink>)

  return (
    <>
        {/* Page Header */}
        <PageHeader title="Projects" buttons={[addButton]} />

        {/* Search bar */}
        <div className="container mt-5">
          <label className="block" htmlFor="projectsearch">Search bar</label>
          <input type="text" className="outline-none shadow rounded bg-white border border-solid border-gray-300 py-1 px-2 w-79 focus:ring-secondary focus:border-secondary" onChange={updateProjectSearchFromForm} id="projectsearch" placeholder="Enter project name" value={projectSearch} />
        </div>

        {/* Container */}
        <div className="container mt-10">
          <div className="flex flex-col items-center max-w-md">
            {projectList}          
            <Pagination decreasePage={decreasePage} increasePage={increasePage} currentPage={currentPage} totalPages={totalPages} />
          </div>
        </div>      
    </>
  );
}

export default ProjectList; 