import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios'
import DescriptionListItem from '../components/DescriptionListItem'
import Loading from '../components/Loading';
import PageHeader from '../components/PageHeader';
import Text from '../components/Form/Text';
import MultiText from '../components/Form/MultiText';
import MultiSelect from '../components/MultiSelect';
import ButtonLoadingSpinner from '../components/ButtonLoadingSpinner';
import ResourceNotFound from '../components/ResourceNotFound';
import * as icons from '../components/Icons';

const ProjectEdit = props => {
  const [projectDetail, setProjectDetail] = useState(null)
  const [tempProjectDetail, setTempProjectDetail] = useState(null)
  const [allAccountsList, setAllAccountsList] = useState(null)
  const [allServicesList, setAllServicesList] = useState(null)
  const [loadingApiCallInProgress, setLoadingApiCallInProgress] = useState(true)
  const [projectNotFound, setProjectNotFound] = useState(null)

  //submit
  const [apiCallInProgress, setApiCallInProgress] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessages, setErrorMessages] = useState([])

  const baseUpdateTempProjectDetail = (field, value) => {
    const copyOfDetail = JSON.parse(JSON.stringify(tempProjectDetail))
    copyOfDetail[field] = value
    setTempProjectDetail(copyOfDetail)
  }

  const updateProjectBillingRef = event => {
    const { value } = event.currentTarget
    baseUpdateTempProjectDetail('BillingRef', value)
  }

  const updateProjectBillingContacts = contacts => {
    baseUpdateTempProjectDetail('BillingContacts', contacts)
  }

  const updateProjectServiceContacts = contacts => {
    baseUpdateTempProjectDetail('ServiceContacts', contacts)
  }

  const updateProjectSecurityContacts = contacts => {
    baseUpdateTempProjectDetail('SecurityContacts', contacts)
  }

  const updateProjectOwner = contacts => {
    baseUpdateTempProjectDetail('ProjectOwner', contacts)
  }

  const updateProjectAccounts = (item, wasSelected) => {
    const copyOfAccounts = [...tempProjectDetail.Accounts]
    if (wasSelected) {
      const index = copyOfAccounts.indexOf(item.value)
      copyOfAccounts.splice(index, 1)
    } else {
      copyOfAccounts.push(item.value)
    }
    baseUpdateTempProjectDetail('Accounts', copyOfAccounts)
  }

  const updateProjectServices = (item, wasSelected) => {
    const copyOfServices = [...tempProjectDetail.Services]
    const formattedItem = { Name: item.label, Tag: item.value }
    if (wasSelected) {
      const index = copyOfServices.indexOf(formattedItem)
      copyOfServices.splice(index, 1)
    } else {
      copyOfServices.push(formattedItem)
    }
    baseUpdateTempProjectDetail('Services', copyOfServices)
  }

  // Get the "tag" parameter from the URL
  const { tag: projectTag } = useParams()

  // Get the list of accounts
  const getAccounts = async () => {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/accounts`,
      headers: { 'content-type': 'application/json' },
    })

    setAllAccountsList(response.data.Accounts)
  }

  // Get the list of services
  const getServices = async () => {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/services`,
      headers: { 'content-type': 'application/json' },
    })

    setAllServicesList(response.data.Services)
  }

  // Get the project to edit
  const getProject = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/projects/${projectTag}`,
        headers: { 'content-type': 'application/json' },
      })
      setProjectDetail(response.data)
      setTempProjectDetail(response.data)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setProjectNotFound(error.response.data)
      }
      throw error
    }
  }

  const initialiseData = async () => {
    await getProject()
    await getServices()
    await getAccounts()
  }

  // When the component renders for the first time, load the data from API
  useEffect(() => {
    initialiseData().then(() => {
      setLoadingApiCallInProgress(false)
    }).catch(error => console.log(error))
  }, [projectTag])

  if (projectNotFound) {
    return <ResourceNotFound pageTitle="Project view" message={projectNotFound.Message} />
  }

  // React will try and render the component before the useEffect executes, so we need to handle
  // what to show when we don't yet have data to show
  if (loadingApiCallInProgress) {
    return <Loading pageTitle="Project edit" />
  }

  const accountsForMultiSelect = allAccountsList.map(account => {
    return {
      label: `${account.Name} (${account.Number})`,
      value: account.Number
    }
  })

  const selectedAccounts = tempProjectDetail.Accounts.map(a => {
    return {
      label: a,
      value: a
    }
  })

  const servicesForMultiSelect = allServicesList.map(service => {
    return {
      label: service.Name,
      value: service.Tag
    }
  })

  const selectedServices = tempProjectDetail.Services.map(svc => {
    return {
      label: svc.Name,
      value: svc.Tag
    }
  })

  const projectReferenceInput = <Text name="project_billingref" id="project_billingref" value={tempProjectDetail.BillingRef} onChange={updateProjectBillingRef} />
  const billingContactsInput = <MultiText value={projectDetail.BillingContacts} onChange={updateProjectBillingContacts} />
  const serviceContactsInput = <MultiText value={projectDetail.ServiceContacts} onChange={updateProjectServiceContacts} />
  const securityContactsInput = <MultiText value={projectDetail.SecurityContacts} onChange={updateProjectSecurityContacts} />
  const projectOwnerInput = <MultiText value={projectDetail.ProjectOwner} onChange={updateProjectOwner} />
  const accountsInput = <div className="-mt-1"><MultiSelect placeholderText={'Choose project accounts'} items={accountsForMultiSelect} selected={selectedAccounts} onOptionClick={updateProjectAccounts} /></div>
  // const servicesInput = <div className="-mt-1"><MultiSelect placeholderText={'Choose project services'} items={servicesForMultiSelect} selected={selectedServices} onOptionClick={updateProjectServices}/></div>

  //Submit changes

  const callAPI = () => {
    setApiCallInProgress(true)

    // using built in JSON utility package turn object to string and store in a variable
    const body = {
      "billingref": String(tempProjectDetail['BillingRef']), // make sure it's a string. even if reference is a number
      "billingemail": tempProjectDetail['BillingContacts'],
      "securityemail": tempProjectDetail['SecurityContacts'],
      "serviceemail": tempProjectDetail['ServiceContacts'],
      "projectowner": tempProjectDetail['ProjectOwner'],
      "accountNumberOutput": tempProjectDetail['Accounts'].map(number => { return { number } }),
    };

    // make API call with parameters and use promises to get response
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/projects/${projectTag}`,
      data: JSON.stringify(body),
      headers: { 'content-type': 'application/json' },
    }).then(response => {
      setErrorMessages([])
      setApiCallInProgress(false)
      setSuccessMessage(response.data.Message)
    }).catch(error => {
      console.log(error);
      const tempErrorMessages = []
      const fieldNameMap = {
        projectname: "Project name",
        billingref: "Billing reference",
        billingemail: "Billing contact email",
        securityemail: "Security contact email",
        serviceemail: "Service contact email",
        projectowner: "Project owner",
        accountNumberOutput: "Account numbers"
      }
      for (const [field, messages] of Object.entries(error.response.data.errors)) {
        const mappedField = fieldNameMap[field]
        for (const msg of messages) {
          tempErrorMessages.push(`${mappedField}: ${msg}`)
        }
      }
      setSuccessMessage("")
      setApiCallInProgress(false)
      setErrorMessages(tempErrorMessages)
    });
  }

  const services = projectDetail.Services.map(svc => {
    return {
      label: svc.Name,
      url: `/services/${svc.Tag}`
    }
  })

  const renderedErrorMessages = errorMessages.map((msg, key) => <div className="mb-2" key={key}>{msg}</div>)

  return (
    <>
      {/* Page Header */}
      <PageHeader title={projectDetail.Project} />

      {/* Container */}
      <div className="container mt-10 flex">
        <div className="w-1/3">
          <div className="pr-5">
            <div className="text-lg font-bold tracking-normal text-gray-900">Edit project</div>
            <div className="text-gray-600 mt-1">Editing the information here will permanently change data regarding this project.</div>
          </div>
        </div>
        {/* Form in white box */}
        <div className="w-2/3 bg-white rounded-lg box-border shadow-md">
          {successMessage && (
            <div className="p-8">
              <div className="rounded bg-green-500 text-white text-base p-4 mb-5">
                {successMessage}
              </div>
            </div>
          )}
          {errorMessages.length > 0 && (
            <div className="p-8">
              <div className="rounded bg-red-500 text-white text-base p-4 mb-5">
                {renderedErrorMessages}
              </div>
            </div>
          )}
          <DescriptionListItem label="Project name:" value={projectDetail.Project} />
          <DescriptionListItem label="Billing reference:" value={projectReferenceInput} />
          <DescriptionListItem label="Tag:" value={projectDetail.Tag} />
          <DescriptionListItem label="Billing contacts:" value={billingContactsInput} />
          <DescriptionListItem label="Security contacts:" value={securityContactsInput} />
          <DescriptionListItem label="Service contacts:" value={serviceContactsInput} />
          <DescriptionListItem label="Project owner:" value={projectOwnerInput} />
          <DescriptionListItem label="Accounts:" value={accountsInput} />
          <DescriptionListItem 
            label="Services:" 
            value={{ 
              type: "table", 
              icon: icons.service, 
              description: "The services linked to an project cannot be edited from here. Edit the service directly to manage its project relationships.",
              items: services 
            }} 
          />
          <div className="bg-gray-200 rounded-bl rounded-br flex justify-end py-3 px-8">
            <button className="bg-primary text-white rounded py-2 px-5 flex items-center" type="button" onClick={callAPI}>
              <div>Update project</div>
              {apiCallInProgress && <ButtonLoadingSpinner />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectEdit;