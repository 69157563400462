import DescriptionListItemBase from './DescriptionListItemBase'
import DescriptionListItemTable from './DescriptionListItemTable'

const DescriptionListItem = props => {
  let { value, url } = props;

  if (value.type === "table") {
    value = <DescriptionListItemTable icon={value.icon} description={value.description} items={value.items} italic={value.italic} />
  }

  return (
    <DescriptionListItemBase
      label={props.label}
      value={value}
      url={url}
    />
  )
}

export default DescriptionListItem;