import React from 'react'

const SuccessMessage = props => {
  const { message } = props

  if (message === null) {
    return null
  }

  return (
    <div className="p-8">
      <div className="rounded bg-green-500 text-white text-base p-4 mb-5">
        {message}
      </div>
    </div>
  )
}

export default SuccessMessage
