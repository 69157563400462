import React from 'react'
import PageHeader from './PageHeader'

const Deleting = props => {
  return (
    <div className="w-full">
      {props.pageTitle && <PageHeader title={props.pageTitle} />}
      <div className="flex items-center justify-start mt-10 container">
        {props.inProgress ?
          (<>
            <svg className="animate-spin mr-5 h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div className="font-medium text-gray-500 text-2xl">
              Deleting...
            </div>
          </>) :
          (<>
            <svg className="mr-5 h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            <div className="font-medium text-gray-500 text-2xl">
              Deleted
            </div>
          </>)
        }
      </div>
    </div>
  )
}

export default Deleting
