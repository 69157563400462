import React from 'react'
import Swal from 'sweetalert2';

const InformationModal = props => {
  const { title, text } = props
  const informationModal = () => {
    Swal.fire({
      title: title,
      text: text,
      buttonsStyling: false,
      allowEnterKey: false,
      customClass: {
        confirmButton: 'hover:bg-indigo-700 text-white px-3 py-2 rounded-md text-sm font-medium bg-indigo-500'
      }
    })
  }
  return (
    <button className="hover:bg-indigo-700 rounded-full border-2 border-indigo-600 h-5 w-5 flex items-center justify-center hover:text-white" type="button" onClick={informationModal}>
    ?
  </button>
  )
}

export default InformationModal
