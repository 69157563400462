import { useState, useEffect } from 'react';
import axios from 'axios';
import PageHeader from '../components/PageHeader';
import { Text, Label, MultiText, RadioGroup } from '../components/Form/index';
import InformationModal from '../components/InformationModal';
import ButtonLoadingSpinner from '../components/ButtonLoadingSpinner';
import Select from '../components/Select';

const AccountCreate = () => {
  // State variables and setter functions for the form fields
  const [accountName, setAccountName] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountPlatform, setAccountPlatform] = useState("")
  const [accountScope, setAccountScope] = useState("")
  const [accountEnvironment, setAccountEnvironment] = useState("")
  const [project, setProject] = useState(null)
  const [projectsToSelectFrom, setProjectsToSelectFrom] = useState([])
  const [billingContacts, setBillingContacts] = useState([])
  const [securityContacts, setSecurityContacts] = useState([])
  const [serviceContacts, setServiceContacts] = useState([])
  const [accountStatus, setAccountStatus] = useState("")

  // State variables and setter functions for form submission feedback
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessages, setErrorMessages] = useState([])
  const [apiCallInProgress, setApiCallInProgress] = useState(false)

  const updateAccountName = event => {
    setAccountName(event.currentTarget.value)
  }

  const updateAccountNumber = event => {
    setAccountNumber(event.currentTarget.value)
  }

  const updateAccountPlatform = event => {
    setAccountPlatform(event.currentTarget.value)
  } 

  const updateAccountScope = event => {
    setAccountScope(event.currentTarget.value)
  }

  const updateAccountStatus = event => {
    setAccountStatus(event.currentTarget.value)
  } 

  const updateAccountEnvironment = event => {
    setAccountEnvironment(event.currentTarget.value)
  }

  const updateProject = item => {
    setProject(item)
  }

  const updateAccountBillingContacts = val => {
    setBillingContacts(val)
  }

  const updateAccountSecurityContacts = val => {
    setSecurityContacts(val)
  }

  const updateAccountServiceContacts = val => {
    setServiceContacts(val)
  }



  // Send the data we've collected in the form off to the API and handle success/error
  const addAccount = async () => {
    setApiCallInProgress(true)

    const body = {
      "Number": accountNumber,
      "Name": accountName,
      "Platform": accountPlatform,
      "Scope": accountScope,
      "Environment": accountEnvironment,
      "Status": accountStatus,
      "Project": project ? project.value : null,
      "BillingContacts": billingContacts,
      "SecurityContacts": securityContacts,
      "ServiceContacts": serviceContacts
    };
    
    try {
      
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/accounts`,
        data: JSON.stringify(body),
        headers: { 'content-type': 'application/json' },
      })
      setErrorMessages([])
      setApiCallInProgress(false)
      setSuccessMessage(response.data.Message)
    } catch (error) {
      console.log(error.response)
      const tempErrorMessages = []
      const fieldNameMap = {
        Number: "Account number",
        Name: "Account name",
        Platform: "Platform",
        Scope: "Account scope",
        Status: "Account Status",
        Environment: "Account environment",
        Project: "Project",
        BillingContacts: "Billing contacts",
        SecurityContacts: "Security contacts",
        ServiceContacts: "Service contacts"
      }
      for (const [field, messages] of Object.entries(error.response.data.errors)) {
        const mappedField = fieldNameMap[field]
        for (const msg of messages) {
          tempErrorMessages.push(`${mappedField}: ${msg}`)
        }
      }
      setApiCallInProgress(false)
      setErrorMessages(tempErrorMessages)
    }
  }

  // Make an API request to the projects endpoint so we can populate our dropdown
  const getProjects = async () => {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/projects`,
      headers: {'content-type': 'application/json'}
    })
    const projects = response.data.Projects.map(proj => {
      return { label: proj.Tag, value: proj.Tag }
    })
    setProjectsToSelectFrom(projects)
  }

  // Format the error messages into something we can render
  const renderedErrorMessages = errorMessages.map((msg, key) => <div key={key}>{msg}</div>)

  // Define the different options for the account scope, to be used in the radio buttons
  const platformOptions = [
    {'id': 'platform_gaws', 'value': 'GAWS', 'label': 'GAWS'},
    {'id': 'platform_gawsref', 'value': 'GAWSREF', 'label': 'GAWSREF'},
  ]

  const statusOptions = [
    {'id': 'status_active', 'value': 'active', 'label': 'Active'},
    {'id': 'status_suspended', 'value': 'suspended', 'label': 'Suspended'},
    {'id': 'status_closed', 'value': 'closed', 'label': 'Closed'},
    {'id': 'status_terminated', 'value': 'terminated', 'label': 'Terminated'},        
  ]

  const scopeOptions = [
    {'id': 'scope_core', 'value': 'core', 'label': 'Core'},
    {'id': 'scope_managed', 'value': 'managed', 'label': 'Managed'},
    {'id': 'scope_bespoke', 'value': 'bespoke', 'label': 'Bespoke'},
  ]

  const environmentOptions = [
    {'id': 'environment_development', 'value': 'development', 'label': 'Development'},
    {'id': 'environment_test', 'value': 'test', 'label': 'Test'},
    {'id': 'environment_production', 'value': 'production', 'label': 'Production'},
  ]

  // Get all projects on component mount
  useEffect(() => {
    getProjects()
  }, [])

  return (
    <>
      {/* Page Header */}
      <PageHeader title="Add new account" />

      {/* Container */}
      <div className="container mt-10 flex">
        <div className="w-1/3">
          <div className="pr-5">
            <div className="text-lg font-bold tracking-normal text-gray-900">Account information</div>
            <div className="text-gray-600 mt-1">Provide information here about the account that you are adding to the inventory.</div>
          </div>
        </div>
        {/* Form in white box */}
        <div className="w-2/3 bg-white rounded-lg box-border shadow-md">
          <div className="p-8 space-y-5">
             {successMessage && (
              <div className="rounded bg-green-500 font-medium text-sm text-white p-4">
                {successMessage}
              </div>
            )}
            {errorMessages.length > 0 && (
              <div className="rounded bg-red-500 font-medium text-white text-sm p-4">
                {renderedErrorMessages}
              </div>
            )}

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
              <div className="space-y-5">
                {/* Account name */}
                <div>
                  <div className="flex items-center">
                  <Label htmlFor="accountName" text="Account name" />
                  <div className="mx-1"><InformationModal title="Account name" text="This is the name of the account number holder, it can be altered after creation."/></div>
                  </div>
                  <Text name="accountName" id="accountName" onChange={updateAccountName} value={accountName} />
                </div>

                {/* Account number */}
                <div>
                  <div className="flex items-center">
                  <Label htmlFor="accountNumber" text="Account number" />
                  <div className="mx-1"><InformationModal title="account number" text="The account number is a crucial component of any project, note that it cannot be changed after creation."/></div>
                  </div>
                  <Text name="accountNumber" id="accountNumber" onChange={updateAccountNumber} value={accountNumber} />
                </div>

                {/* Project */}
                <div>
                  <div className="flex items-center">
                  <Label htmlFor="project" text="Project" />
                  <div className="mx-1">
                  <InformationModal 
                  title="Assign a project" 
                  text="To avoid unassigned accounts being on the system, you cannot create an account without assigning to a pre-existing project. 
                  Consider creating the project if it does not already exist."/>
                  </div>
                  </div>
                  <Select
                    placeholderText="Choose a project"
                    items={projectsToSelectFrom}
                    selected={project}
                    onSelect={updateProject}
                  />
                </div>
              </div>

              {/* Account scope/platform/environment */}
              <div>
                <Label text="Platform" />
                <RadioGroup name="account_platform" options={platformOptions} onSelect={updateAccountPlatform} />
                <div className="py-3"/>
                <Label text="Account scope" />
                <RadioGroup name="account_scope" options={scopeOptions} onSelect={updateAccountScope} />
                <div className="py-3"/>
                <Label text="Account environment" />
                <RadioGroup name="account_environment" options={environmentOptions} onSelect={updateAccountEnvironment} />
                <div className="py-3"/>        
                <Label text="Account Status" />
                <RadioGroup name="account_status" options={statusOptions} onSelect={updateAccountStatus} />
              </div>
            </div>

            <hr />            

            {/* Billing contacts */}
            <div>
              <Label text="Billing contacts" />
              <MultiText value={billingContacts} onChange={updateAccountBillingContacts} />
            </div>

            {/* Security contacts */}
            <div>
              <Label text="Security contacts" />
              <MultiText value={securityContacts} onChange={updateAccountSecurityContacts} />
            </div>

            {/* Service contacts */}
            <div>
              <Label text="Service contacts" />
              <MultiText value={serviceContacts} onChange={updateAccountServiceContacts} />
            </div>

            <hr />

          </div>

          <div className="bg-gray-200 rounded-bl rounded-br flex justify-end py-3 px-8">
            <button className="bg-primary text-white rounded py-2 px-5 flex items-center" type="button" onClick={addAccount}>
              <div>Add account</div>
              {apiCallInProgress && <ButtonLoadingSpinner />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountCreate;